import { KEYCLOAK_LOGIN } from "utils/constants";

// eslint-disable-next-line no-undef
const configData = process.env;
let config = {};
const userDetails = `{"password":"admin","provider":"db","refresh":true,"username":"admin"}`;
//https://profile.srcm.net/uep/
//https://uep-api.in.ngrok.io/
try {
  config.apiURI =
    configData.REACT_APP_API_BASE_URI || "https://profile.srcm.net/uep/";
  config.apiSRCMURI =
    configData.REACT_APP_API_SRCM_BASE_URI || "https://profile.srcm.net/";
  config.locationAPIURI =
    configData.REACT_APP_LOCATION_API_URI ||
    "https://location-service-grpc.qa.heartfulnessinstitute.in/";
  config.eventRegURL =
    configData.REACT_APP_EVENT_REG_BASE_URL ||
    "https://eventreg.staging.heartfulness.org";
  config.xClientIdSRCM =
    configData.REACT_APP_SRCM_XCLIENTID ||
    "sNoCucDYc1ok5D8HzktKJUROtXGlD49tSGIPiXzn";
  config.xClientIdPreceptor =
    configData.REACT_APP_PRECEPTOR_XCLIENTID ||
    "KerzAGDfUaPIFS0ziqtvQ2rUQv39XmwbbUHcs8EK";
  config.NODE_ENV = configData.NODE_ENV;
  config.placesAutoCompleteURI =
    configData.REACT_APP_PLACES_AUTOCOMPLETE_URI ||
    "https://us-central1-hfn-registration-qa.cloudfunctions.net/";
  config.srcmStaticDataURI =
    configData.REACT_APP_STATIC_DATA_SRCM_URI ||
    "https://static-gatsby-qa.web.app/";
  config.srcmStaticNewDataURI =
    configData.REACT_APP_STATIC_NEW_DATA_SRCM_URI ||
    "https://static-api.qa.heartfulness.org/";
  config.qrCodeURI =
    configData.REACT_APP_QRCODE_URI ||
    "https://3sdrj8o4oj.execute-api.us-east-1.amazonaws.com";
  config.myHFNURL =
    configData.REACT_APP_MYHFN_URL || "https://gatsby-qa-site-my.web.app";
  config.baseUrl =
    configData.REACT_APP_SELFREG_URI || "https://uep.staging.heartfulness.org";
  config.firebaseAuthConfig = configData.REACT_APP_FIREBASE_AUTH_CONFIG || "";
  config.staticFileDataURI =
    configData.REACT_APP_STATIC_FILE_DATA_URI ||
    "https://static-file-staging.web.app/";
  config.mysrcmConfig = configData.REACT_APP_MYSRCM_CONFIG || {
    xClientId: "u4g9FxYdcjAZVAGfEQw1TWxVnskG1o8y6axOfVVH",
    profileServer: "https://uep-api.in.ngrok.io",
  };
  config.sampleAttendanceURI =
    configData.SAMPLE_ATTENDANCE_CDN_URL ||
    "https://cdn-prod.heartfulness.org/images/excel/sample-data.xlsx";
  config.youthUniteAttendanceURI =
    configData.YOUTH_UNITE_ATTENDANCE_CDN_URL ||
    "https://cdn-prod.heartfulness.org/images/2024/adhoc/youth_unite_participant_data.xlsx";
  config.apiOmsURI =
    configData.REACT_APP_ORDER_SERVICE_BASE_URI ||
    "https://order-management-service-grpc.qa.heartfulnessinstitute.in/";
  config.defaultAshram = configData.REACT_APP_DEFAULT_ASHRAM || {
    value: 1558,
    label: "SRCM Ashram-Kanha",
  };
  config.baseUrlReport =
    configData.REACT_APP_API_REPORT_BASE_URI ||
    "https://apache-superset.dev.heartfulnessinstitute.in";
  config.firebaseidSearchBaseUrl =
    `${config.apiSRCMURI}api/position-assignments/fetch` ||
    `https://profile.srcm.net/api/position-assignments/fetch`;
  config.embedDashboardIdPLMES =
    configData.REACT_APP_EMBEDDED_DASHBOARD_ID_PLMES ||
    "c977307a-08ff-4ba8-8ec4-51ece5671bbf";
  config.embedDashboardIdRF =
    configData.REACT_APP_EMBEDDED_DASHBOARD_ID_RF ||
    "afad9de2-946e-41e9-a68b-b9c72e1efe83";
  config.embedDashboardIdZC =
    configData.REACT_APP_EMBEDDED_DASHBOARD_ID_ZC ||
    "36abfd0a-7b35-48f6-b113-498f930e8c19";
  config.embedDashboardIdCC =
    configData.REACT_APP_EMBEDDED_DASHBOARD_ID_CC ||
    "6c534a11-8fe8-4c8a-979e-b1de07ef57e8";
  config.embedDashboardIdMobilePLMES =
    configData.REACT_APP_EMBEDDED_DASHBOARD_ID_APP_PLMES ||
    "f1b855d6-a6ff-492a-a058-2fa748a3eb17";
  config.embedDashboardIdMobileRF =
    configData.REACT_APP_EMBEDDED_DASHBOARD_ID_APP_RF ||
    "f59d8376-dc32-4dc7-8a26-ed2807b91257";
  config.embedDashboardIdMobileZC =
    configData.REACT_APP_EMBEDDED_DASHBOARD_ID_APP_ZC ||
    "b7909c54-6807-45d9-8bb6-802afa4bab83";
  config.embedDashboardIdMobileCC =
    configData.REACT_APP_EMBEDDED_DASHBOARD_ID_APP_CC ||
    "5a2c2414-3674-42c5-bf17-2fff1940cf8f";
  config.loginUserDetails =
    configData.REACT_APP_APACHE_REPORT_USER || userDetails;
  config.loginType = configData.REACT_APP_HEP_LOGIN_TYPE;
  config.keycloakAuthConfig =
    configData.REACT_APP_KEYCLOAK_AUTH_CONFIG ||
    '{"authUrl":"https://authadmin.dev.heartfulness.org","realm":"heartfulness-qa","client_id":"heartfulness_website"}';
} catch {
  config.apiURI = "https://profile.srcm.net/uep/";
  config.eventRegURL = "https://eventreg.staging.heartfulness.org/";
  config.locationAPIURI =
    "https://location-service-grpc.qa.heartfulnessinstitute.in/";
  config.placesAutoCompleteURI =
    "https://us-central1-hfn-registration-qa.cloudfunctions.net/";
  config.srcmStaticDataURI = "https://static-gatsby-qa.web.app/";
  config.srcmStaticNewDataURI = "https://static-api.qa.heartfulness.org/";
  config.staticFileDataURI = "https://static-file-staging.web.app/";
  config.mysrcmConfig = {
    xClientId: "u4g9FxYdcjAZVAGfEQw1TWxVnskG1o8y6axOfVVH",
    profileServer: "https://uep-api.in.ngrok.io",
  };
  config.qrCodeURI = "https://3sdrj8o4oj.execute-api.us-east-1.amazonaws.com";
  config.myHFNURL =
    configData.REACT_APP_MYHFN_URL || "https://gatsby-qa-site-my.web.app";
  config.baseUrl = "https://uep.staging.heartfulness.org";
  config.sampleAttendanceURI =
    "https://cdn-prod.heartfulness.org/images/excel/sample-data.xlsx";
  config.youthUniteAttendanceURI =
    "https://cdn-prod.heartfulness.org/images/2024/adhoc/youth_unite_participant_data.xlsx";
  config.apiOmsURI =
    "https://order-management-service-grpc.qa.heartfulnessinstitute.in/";
  config.defaultAshram = { value: 1558, label: "SRCM Ashram-Kanha" };
  config.baseUrlReport = "https://apache-superset.dev.heartfulnessinstitute.in";
  config.firebaseidSearchBaseUrl =
    "https://profile.srcm.net/api/position-assignments/fetch";
  config.embedDashboardIdPLMES = "c977307a-08ff-4ba8-8ec4-51ece5671bbf";
  config.embedDashboardIdRF = "afad9de2-946e-41e9-a68b-b9c72e1efe83";
  config.embedDashboardIdZC = "36abfd0a-7b35-48f6-b113-498f930e8c19";
  config.embedDashboardIdCC = "6c534a11-8fe8-4c8a-979e-b1de07ef57e8";
  config.embedDashboardIdMobilePLMES = "f1b855d6-a6ff-492a-a058-2fa748a3eb17";
  config.embedDashboardIdMobileRF = "f59d8376-dc32-4dc7-8a26-ed2807b91257";
  config.embedDashboardIdMobileZC = "b7909c54-6807-45d9-8bb6-802afa4bab83";
  config.embedDashboardIdMobileCC = "5a2c2414-3674-42c5-bf17-2fff1940cf8f";
  config.loginUserDetails = userDetails;
  config.loginType = KEYCLOAK_LOGIN;
  config.keycloakAuthConfig =
    '{"authUrl":"https://authadmin.dev.heartfulness.org","realm":"heartfulness-qa","client_id":"heartfulness_website"}';
}

config.appURI = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

config.gPlacesSession = "74c576ef-7234-4f47-8b11-f8e41d247f3b";

export default config;
